import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import { Loadable, Loaded, NotLoaded } from 'store/util';
import { LiveStream } from 'types';

const youtubeChannelParams = `
    id,
    video_id,
    title,
    description,
    thumbnail,
    live,
`;
export const getLivestreamQuery = (channelId: string): Loadable<LiveStream> => {
  const { data } = useQuery(
    gql`{
        youtubeChannel (id: "${channelId}") {
            ${youtubeChannelParams}
        }
    }`,
  );
  if (data !== undefined) {
    return Loaded(data.youtubeChannel);
  }
  return NotLoaded;
};
