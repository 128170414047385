import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';

import { Podcast } from 'types';

import { podcastShow as podcastFragment } from './fragments';
import { Loadable, Loaded, NotLoaded, NotFound } from './util';

const podcastsQuery = gql`
  query AllPodcasts {
    podcastShows {
      ${podcastFragment}
    }
  }
`;

const podcastBySlugQuery = (slug: string) => {
  return gql`
    query PodcastBySlug {
      podcastShow(slug:"${slug}") {
        ${podcastFragment}
      }
    }
  `;
};

const getBySlug = (slug: string): Loadable<Podcast> => {
  const { data } = useQuery(podcastBySlugQuery(slug));
  if (data === undefined) {
    return NotLoaded;
  }
  if (data.podcastShow === null) {
    return NotFound;
  }
  return Loaded(data.podcastShow);
};

const getAll = (): Loadable<Array<Podcast>> => {
  const { data } = useQuery(podcastsQuery);
  if (data === undefined) {
    return NotLoaded;
  }
  return Loaded(data.podcastShows);
};

export { getBySlug, getAll };
