import dateFormat from 'dateformat';

import Grid from 'components/Grid';
import TwitchPlayer from 'components/TwitchPlayer';
import CopyLinkButton from 'components/CopyLinkButton';
import { FacebookShare, TwitterShare } from 'components/Social';
import { LiveStream } from 'types';

import styles from './index.module.scss';

const StreamingHero = ({ liveStream }: { liveStream: LiveStream }) => {
  return (
    <div className={styles.background}>
      <Grid container full className={styles.streamingHeroContainer}>
        <Grid item sm={12} md={8} lg={8} className={styles.heroPosterContainer}>
          <TwitchPlayer id="therecount" autoplay={true} muted={true} className={styles.iframe} />
        </Grid>

        <Grid
          sm={12}
          md={4}
          lg={4}
          direction="column"
          justifyContent="space-between"
          className={styles.headerContent}
        >
          <div className={styles.contentMeta}>
            <span className={styles.sectionTitle}>Recount TV</span>
            <span className={styles.date}>{dateFormat(new Date(), 'dddd mm.dd.yy')}</span>
          </div>
          <h2 className={styles.heading}>{liveStream.title}</h2>

          <div className={styles.headerText}>
            <p>{liveStream.description}</p>
            <div className={styles.socialIcons}>
              <CopyLinkButton clipboardText="https://therecount.com/" />
              <TwitterShare url="https://therecount.com" />
              <FacebookShare url="https://therecount.com" />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default StreamingHero;
