import { config } from 'shared/config';

// Strip out ports for local dev
const domain = config.websiteHost
  ?.replace(/^https?:\/\//, '')
  ?.replace(/:[0-9]+$/, '')
  ?.replace(/\//, '');

export interface TwitchPlayerProps {
  id: string;
  width?: number;
  height?: number;
  autoplay?: boolean;
  muted?: boolean;
  className?: string;
}
const TwitchPlayer: React.FC<TwitchPlayerProps> = ({
  id,
  width,
  height,
  autoplay,
  muted,
  className,
}) => {
  const _autoplay = autoplay == undefined ? false : autoplay;
  const _muted = muted == undefined ? false : muted;
  return (
    <iframe
      src={`https://player.twitch.tv/?channel=${id}&autoplay=${_autoplay}&muted=${_muted}&parent=${domain}`}
      width={width}
      height={height}
      className={className || ''}
      allowFullScreen={true}
    ></iframe>
  );
};

export default TwitchPlayer;
