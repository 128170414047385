import Grid from 'components/Grid';
import SectionHeader from 'components/SectionHeader';
import styles from './index.module.scss';
import { Slider } from 'components/Slider';
import { VideoTile } from 'components/VideoTile';
import { Newsletter } from 'types';

interface BeehiivSectionProps {
  posts: Array<Newsletter>;
}

const BeehiivSection: React.FC<BeehiivSectionProps> = ({ posts }) => {
  return (
    <div className={styles.beehiivSection}>
      <Grid container>
        <Grid item sm={12}>
          <SectionHeader className={styles.beehiivHeading}>Newsletters</SectionHeader>
        </Grid>
      </Grid>
      <div className={styles.sliderContainer}>
        <Slider
          items={posts.map((post) => (
            <VideoTile
              video={post}
              key={post.id}
              href={`https://newsletter.therecount.net/p/${post.slug}`}
              imageWidth={330}
              externalLink
            />
          ))}
          position={0}
          height="500px"
          pageSize={4}
        />
      </div>
    </div>
  );
};

export default BeehiivSection;
