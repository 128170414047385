import React from 'react';

import { VideoPlayer } from 'components/VideoPlayer';
import { Video } from 'types';
import Grid from 'components/Grid';

import dateFormat from 'dateformat';
import CopyLinkButton from 'components/CopyLinkButton';
import { FacebookShare, TwitterShare } from 'components/Social';
import { truncateText } from 'shared/util';
import styles from './index.module.scss';

const VideoHero = ({ video }: { video: Video<'renditions' | 'tags' | 'sections' | 'show'> }) => {
  const description = (desc: string | null) => {
    if (desc === null) {
      return '';
    }
    if (desc.length <= 390) {
      return desc;
    }

    return (
      <>
        {truncateText(desc, 390, { reserve: 9 })}
        <a className={styles.permaLink} href={`/watch/${video.slug}/${video.id}`}>
          {' '}
          see more
        </a>
      </>
    );
  };

  return (
    <div className={styles.background}>
      <Grid container full className={styles.streamingHeroContainer}>
        <Grid item sm={12} md={8} lg={8} className={styles.heroPosterContainer}>
          <VideoPlayer autoplay={false} video={video} preserveAspectRatio={false} />
        </Grid>

        <Grid
          sm={12}
          md={4}
          lg={4}
          direction="column"
          justifyContent="space-between"
          className={styles.headerContent}
        >
          <div className={styles.contentMeta}>
            <a
              href={`/section/${video.sections[0]?.slug ?? 'politics'}`}
              className={styles.sectionTitle}
            >
              {video.sections[0]?.title ?? 'Politics'}
            </a>
            <span className={styles.date}>{dateFormat(new Date(), 'dddd mm.dd.yy')}</span>
          </div>
          <h2 className={styles.heading}>{video.title}</h2>

          <div className={styles.headerText}>
            <p>{description(video.description)}</p>
            <div className={styles.socialIcons}>
              <CopyLinkButton clipboardText={video.shareURL} />
              <TwitterShare url={video.shareURL} text={video.title} />
              <FacebookShare url={video.shareURL} />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export { VideoHero };
