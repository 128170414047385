import { sortBy } from 'ramda';

import * as liveStreamStore from 'store/liveStream';
import * as videoStore from 'store/videos';
import { Loadable } from 'store/util';
import { exhaustive } from 'shared/util';
import * as podcastStore from 'store/podcasts';
import { useHeader } from 'components/Header';
import { useFooter } from 'components/Footer';
import ErrorPage from 'pages/_error.page';

import { DefaultMeta } from 'components/Meta';
import { Newsletter, Video } from 'types';
import { DisplayAd } from 'components/DisplayAd';
import WireSection from 'components/WireSection';
import { VideoHero } from './home/VideoHero';
import StreamingHero from './home/StreamingHero';
import DonationPromptBanner from 'components/DonationPromptBanner';
import YoutubeSection, { YouTubePlaylistDetails } from 'components/YoutubeSection';
import BeehiivSection from 'components/BeehiivSection';
import { getHomepageYoutubeVideos } from 'shared/youtube';
import { getNewsletters } from 'shared/beehiiv';

const AD_SLOT_1 = '/22673562521/Responsive_DisplayAds_ATF';
const AD_SLOT_2 = '/22673562521/Responsive_DisplayAds_BTF';

const REVALIDATE_TIME = 60 * 30;

const homepageYoutubeConfig = [
  {
    heading: 'WTF America',
    playlistId: 'PL3tE7uwBkwPtJH3lqEiIb_gZAdccc0kfT',
  },
  {
    heading: 'Margin of Error with Steve Morris',
    playlistId: 'PL3tE7uwBkwPuJ5KW-_w3FonaGMR-JFA94',
  },
];

export async function getStaticProps() {
  const youtubeVideos = await getHomepageYoutubeVideos(homepageYoutubeConfig);
  const beehiivPosts = await getNewsletters();

  return {
    props: {
      youtubeVideos,
      beehiivPosts,
    },
    revalidate: REVALIDATE_TIME,
  };
}

const Home = ({
  youtubeVideos,
  beehiivPosts,
}: {
  youtubeVideos: YouTubePlaylistDetails[];
  beehiivPosts: Newsletter[];
}) => {
  const videosBySection = videoStore.latestBySection(5);
  const pinnedVideo = videoStore.getPinned();
  const liveStream = liveStreamStore.getLivestreamQuery('1');
  const podcasts = podcastStore.getAll();
  const Header = useHeader();
  const Footer = useFooter();

  const all = Loadable.all([videosBySection, podcasts, liveStream, pinnedVideo]);
  switch (all._tag) {
    case 'NotFound':
      return <ErrorPage statusCode={500} />; // Should never happen.
    case 'NotLoaded':
      return null; // TODO loading state
    case 'Loaded': {
      // eslint-disable-next-line @typescript-eslint/no-shadow, @typescript-eslint/no-unused-vars
      const [videosBySection, podcasts, liveStream, pinnedVideo] = all.data;

      const latestVideo =
        sortBy(
          (v: Video) => -v.publishedAt,
          videosBySection.flatMap((vbs) => vbs.videos),
        )[0] || null;
      return (
        <>
          <DefaultMeta />
          <DisplayAd adSlot={AD_SLOT_1} currentPath="/" headerSlot={true} />
          <Header />
          {(() => {
            if (liveStream.live) {
              return <StreamingHero liveStream={liveStream} />;
            } else if (latestVideo) {
              return <VideoHero video={pinnedVideo ?? latestVideo} />;
            } else {
              return null;
            }
          })()}
          <DonationPromptBanner />
          <WireSection />
          <DisplayAd adSlot={AD_SLOT_2} currentPath="/" />
          <YoutubeSection playlists={youtubeVideos} />
          <BeehiivSection posts={beehiivPosts} />
          <Footer />
        </>
      );
    }
    default:
      return exhaustive(all);
  }
};

export default Home;
