import Grid from 'components/Grid';
import SectionHeader from 'components/SectionHeader';
import styles from './index.module.scss';
import { Slider } from 'components/Slider';
import { TILE_TYPE, VideoTile } from 'components/VideoTile';
import { YouTube } from 'types';

export interface YouTubePlaylistDetails {
  heading: string;
  playlistId: string;
  videos: any;
}

export interface YouTubeSectionProps {
  playlists: YouTubePlaylistDetails[];
}

const YoutubeSection: React.FC<YouTubeSectionProps> = ({ playlists }) => {
  return (
    <div className={styles.youtubeSection}>
      <Grid container>
        <Grid item sm={12}>
          <SectionHeader className={styles.youtubeHeading}>Youtube</SectionHeader>
        </Grid>
      </Grid>

      <>
        {playlists?.map((playlist, index) => (
          <Grid
            key={`playlist-${index}`}
            container
            gap="var(--padding-lg)"
            className={styles.youtubeContainer}
          >
            <Grid item sm={12}>
              <div className={styles.playlistHeading}>
                <h3>{playlist.heading}</h3>
              </div>
            </Grid>
            <div className={styles.sliderContainer}>
              <Slider
                items={playlist.videos.map((video: YouTube) => (
                  <VideoTile
                    key={`youtube-${video.id}`}
                    type={TILE_TYPE.YOUTUBE}
                    href="#"
                    video={video}
                    imageWidth={330}
                  />
                ))}
                position={0}
                height="500px"
                pageSize={4}
              />
            </div>
          </Grid>
        ))}
      </>
    </div>
  );
};

export default YoutubeSection;
